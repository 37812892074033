export const VIEWER_HOOKS = {
  PP_WIDGET_SUBTITLE: 'app-subtitle',
  PLAN_RECURRENCE: 'plan-recurrence',
  BENEFITS: 'benefits',
  BENEFIT: 'benefit',
  EXPAND_BENEFITS: 'expand-benefits',
  PLAN_DURATION: 'plan-duration',
  PLAN_PRICE: 'plan-price',
  PLAN_PRICE_SCREEN_READER: 'plan-price-screen-reader',
  PRICE_CURRENCY: 'price-currency',
  PRICE_AMOUNT: 'price-amount',
  PLAN_FREE_TRIAL: 'plan-free-trial',
  PLAN_CTA_WRAPPER: 'plan-cta-wrapper',
  PLAN_CTA: 'plan-cta',
  PLAN_RIBBON: 'ribbon',
  PLAN_TAGLINE: 'plan-tagline',
  PLAN_NAME: 'plan-title',
};
